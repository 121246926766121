<template>
    <main-layout>
        <div class="main">
                <!-- main-header -->
                <div class="main__header header">
                    <div class="header__wysiwyg wysiwyg">
                        <h2>{{this.userdata.view_user_profile.firstname}} {{this.userdata.view_user_profile.lastname}} Profile page</h2>
                    </div>
                    <div class="header__actions">
                        <div v-if="!userdata.view_user_profile.blocked_me && userdata.view_user_profile.id != userdata.user_profile.id  && !userdata.view_user_profile.private">
                            <div class="btn-group btn-group_secondary">
                                <a v-if="!userdata.view_user_profile.private_message" href="#" @click.prevent.stop="sendMessageCard(this.userdata.view_user_profile)" class="btn btn_secondary btn_secondary-ico">
                                    <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 3C3.55109 3 3.36032 3.07902 3.21967 3.21967C3.07902 3.36032 3 3.55109 3 3.75V13.9393L4.71967 12.2197C4.86032 12.079 5.05109 12 5.25 12H14.25C14.4489 12 14.6397 11.921 14.7803 11.7803C14.921 11.6397 15 11.4489 15 11.25V3.75C15 3.55109 14.921 3.36032 14.7803 3.21967C14.6397 3.07902 14.4489 3 14.25 3H3.75ZM2.15901 2.15901C2.58097 1.73705 3.15326 1.5 3.75 1.5H14.25C14.8467 1.5 15.419 1.73705 15.841 2.15901C16.2629 2.58097 16.5 3.15326 16.5 3.75V11.25C16.5 11.8467 16.2629 12.419 15.841 12.841C15.419 13.2629 14.8467 13.5 14.25 13.5H5.56066L2.78033 16.2803C2.56583 16.4948 2.24324 16.559 1.96299 16.4429C1.68273 16.3268 1.5 16.0533 1.5 15.75V3.75C1.5 3.15326 1.73705 2.58097 2.15901 2.15901Z" fill="#00A961"/>
                                    </svg>
                                    <span class="btn__text">Send message</span>
                                </a>
                                <a v-if="!userdata.view_user_profile.in_my_crm && !userdata.view_user_profile.private && !userdata.view_user_profile.private_message" @click.prevent="addToCRM" href="#" class="btn btn_primary btn_secondary-ico">
                                    <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3C9.41421 3 9.75 3.33579 9.75 3.75V14.25C9.75 14.6642 9.41421 15 9 15C8.58579 15 8.25 14.6642 8.25 14.25V3.75C8.25 3.33579 8.58579 3 9 3Z" fill="white"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9Z" fill="white"/>
                                    </svg>
                                    <span class="btn__text">Add to CRM</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- main-content -->
                <div class="main__content">
                    <div class="profile">
                        <!-- <BasicInformationForm></BasicInformationForm> -->
                        <div class="section">
                            <div class="section__header">
                                <div class="section__wysiwyg wysiwyg">
                                    <h2>Basic information</h2>
                                </div>
                            </div>

                            <div class="section__content">
                                <div class="dl">
                                    <div class="dl__list">
                                        <div class="row">
                                            <div class="col col_6 col_big-desktop-12 col_tab-6 col_mob-12">
                                                <div class="dl__item">
                                                    <div class="dl__title">
                                                        <span class="title-text">First Name</span>
                                                    </div>
                                                    <div class="dl__value">
                                                        <span class="value-text">{{this.userdata.view_user_profile.firstname}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col col_6 col_big-desktop-12 col_tab-6 col_mob-12">
                                                <div class="dl__item">
                                                    <div class="dl__title">
                                                        <span class="title-text">Last Name</span>
                                                    </div>
                                                    <div class="dl__value">
                                                        <span class="value-text">{{this.userdata.view_user_profile.lastname}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col col_6 col_big-desktop-12 col_tab-6 col_mob-12">
                                                <div class="dl__item">
                                                    <div class="dl__title">
                                                        <span class="title-text">Country</span>
                                                    </div>
                                                    <div class="dl__value">
                                                        <span class="value-text">{{this.userdata.view_user_profile.country}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col col_6 col_big-desktop-12 col_tab-6 col_mob-12">
                                                <div class="dl__item">
                                                    <div class="dl__title">
                                                        <span class="title-text">City /Town</span>
                                                    </div>
                                                    <div class="dl__value">
                                                        <span class="value-text">{{this.userdata.view_user_profile.city}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col col_6 col_big-desktop-12 col_tab-6 col_mob-12">
                                                <div class="dl__item">
                                                    <div class="dl__title">
                                                        <span class="title-text">Position</span>
                                                    </div>
                                                    <div class="dl__value">
                                                        <span class="value-text">{{this.userdata.view_user_profile.position}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col col_6 col_big-desktop-12 col_tab-6 col_mob-12">
                                                <div class="dl__item">
                                                    <div class="dl__title"
                                                        v-if="this.userdata.view_user_profile.company_user_type == 'business' ||
                                                              this.userdata.view_user_profile.company_user_type == 'expert' " >
                                                        <span class="title-text">Company</span>
                                                    </div>
                                                    <div class="dl__title"
                                                        v-else-if="this.userdata.view_user_profile.company_user_type == 'investment' ||
                                                                   this.userdata.view_user_profile.company_user_type == 'investor' " >
                                                        <span class="title-text">Business</span>
                                                    </div>
                                                    <div v-else class="dl__title">
                                                        <span class="title-text">Start-Up Name</span>
                                                    </div>
                                                    <div class="dl__value">
                                                        <span class="value-text">
                                                            <router-link :to="{name: 'company_profile', params: {id: this.userdata.view_user_profile.companyid }}">{{this.userdata.view_user_profile.companyname}}</router-link>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col col_6 col_big-desktop-12 col_tab-6 col_mob-12">
                                                <div class="dl__item">
                                                    <div class="dl__title"
                                                        v-if="this.userdata.view_user_profile.company_user_type == 'business' ||
                                                              this.userdata.view_user_profile.company_user_type == 'expert' " >
                                                        <span class="title-text">Field of Expertise</span>
                                                    </div>
                                                    <div class="dl__title"
                                                        v-else-if="this.userdata.view_user_profile.company_user_type == 'investment' ||
                                                                   this.userdata.view_user_profile.company_user_type == 'investor' " >
                                                        <span class="title-text">Field of Focus</span>
                                                    </div>
                                                    <div v-else  class="dl__title">
                                                        <span class="title-text">Industry</span>
                                                    </div>

                                                    <div class="dl__value">
                                                        <span class="value-text">{{this.userdata.view_user_profile.companyindustry}}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col col_6 col_big-desktop-12 col_tab-6 col_mob-12"
                                                v-if="this.userdata.view_user_profile.company_user_type == 'investment' ||
                                                      this.userdata.view_user_profile.company_user_type == 'investor' " >
                                                <div class="dl__item">
                                                    <div class="dl__title">
                                                        <span class="title-text">Type of Investment</span>
                                                    </div>

                                                    <div class="dl__value">
                                                        <span class="value-text">{{this.userdata.view_user_profile.type_of_investment}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="section">
                            <div class="section__header">
                                <div class="section__wysiwyg wysiwyg">
                                    <h2>Description</h2>
                                </div>
                            </div>
                            <div class="section__content">
                                <div class="wysiwyg">
                                    <p>{{ this.userdata.view_user_profile.description }}</p>
                                </div>
                            </div>
                        </div>

                        <Experience :type="'view'"></Experience>

                        <Education :type="'view'"></Education>

                        <Media :type="'view'"></Media>

                    </div>
                </div>
        </div>

        <ViewfilePopup></ViewfilePopup>
    </main-layout>
</template>

<script>
    import MainLayout from '../layouts/Main.vue'
    import store from '../store'
    import Experience from '@/components/profile/Experience'
    import Education from '@/components/profile/Education'
    import Media from '@/components/profile/Media'
    import ViewfilePopup from '@/components/popups/Viewfile'

    export default {
        name: "Profile",
        components: {
            MainLayout,
            Experience,
            Education,
            Media,
            ViewfilePopup,
        },
        data() {
            return {
            }
        },
        methods: {
            getProfile: function(id) {
                store.dispatch('SET_VIEW_USER_PROFILE', id);
            },
            addToCRM: function() {
                store.dispatch('ADD_CONTACT_TO_CRM', { contact_id: this.userdata.view_user_profile.id, type: 'user' });
            },
            sendMessageCard(user) {
                if (user.in_my_team) {
                    store.dispatch('CHAT_USER_CONVERSATION_OPEN', user.id);
                    return;
                }
                let data = {
                    recipient: user.id,
                    type: 'user'
                };
                store.dispatch('SHOW_POPUP', { popup: 'postPersonalMessage', show: true, data: data});
                return;
            },
        },

        created() {
            this.getProfile(this.$route.params.id);
        },
        computed: {
            userdata: () => store.state.user,
        }
    }
</script>
